<template>
  <section
    v-if="product || noProductFlowFlag"
    class="sp__results"
  >
    <div
      v-if="product"
      class="sp__results__wrapper"
    >
      <content-sidebar :custom-props="customProps" />
      <div
        id="js__results-content"
        :class="generateWrapperClasses"
      >
        <component
          :is="selected"
          :custom-props="customProps"
        />
        <Contact
          v-if="!customProps.suppressContact && isAllowableTab"
          :custom-props="customProps"
        />
      </div>
    </div>

    <div
      v-if="noProductFlowFlag"
      id="js__sp__results"
      class="sp__section sp__results__container"
    >
      <div
        id="js__sp__results__no-product"
        class="sp__results__no-product"
      >
        <component
          :is="selected"
          :custom-props="customProps"
        />
        <Contact
          v-if="!customProps.suppressContact"
          :custom-props="customProps"
        />
      </div>
    </div>
  </section>
</template>
<script src="./index.js"></script>
