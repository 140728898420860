<template>
  <div v-if="renderHome">
    <HeroIntroduction
      :children="[{
        component: getProductSearch,
        customProps: {},
        children: [],
      }]"
    />

    <ScQuickLinks
      :heading="quickLinksHeading"
      :accessible-nav-label="quickLinksAccessibleLabel"
      :image="quickLinksImage"
      :image-set="quickLinksImageSet"
      :links="quickLinksList"
      :lazy-load="true"
    >
      <template #prepend>
        <ScSocialBar
          v-if="socialMediaLinks.length > 0"
          class="sc__quick-links-social-bar"
          accessible-nav-label="Social Media Links"
          :links="socialMediaLinks"
        />
      </template>
    </ScQuickLinks>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss">
  @import './index.scss';
</style>
