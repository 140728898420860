// eslint-disable-next-line import/extensions, import/no-unresolved
import 'vite/modulepreload-polyfill';
import get from 'lodash.get';
import {
  utagDirectiveName,
  utagDirective,
  initializeGA4,
} from '@garmin/support-analytics';
import { createApp, h } from 'vue';
import { defaultConfig, plugin as formkit } from '@formkit/vue';
import store from '../store/store';

import Default from '../views/Default.vue';

import('lazysizes');

const app = createApp({ render: () => h(Default) })
  .use(store);

app.config.globalProperties.$safeGet = get;
app.config.globalProperties.$translations = window?.AppData?.translations || {};
app.config.compilerOptions.whitespace = 'preserve';

app.use(formkit, defaultConfig({
  config: {
    classes: {
      messages: 'field-messages',
      message: 'field-message',
      outer: 'sc__form__field-group',
    },
  },
}));

utagDirective.inserted = utagDirective.mounted;
app.directive(utagDirectiveName, utagDirective);

initializeGA4();

app.mount('#app');
