import { mapGetters } from 'vuex';

import ScQuickLinks from '@garmin/support-components/src/components/ScQuickLinks/index.vue';
import ProductSearch from '@garmin/support-center-components/components/product-search/product-search.vue';
import HeroIntroduction from '@garmin/support-center-components/components/hero-introduction/hero-introduction.vue';
import ScSocialBar from '@garmin/support-components/src/components/ScSocialBar/index.vue';

export default {
  name: 'HomeView',
  components: {
    HeroIntroduction,
    ScQuickLinks,
    ScSocialBar,
  },
  computed: {
    getProductSearch() { return ProductSearch; },
    quickLinksHeading() {
      return this.$store.getters.translations.scHomePage.scHomeResourceLinks.groupTitle;
    },
    quickLinksAccessibleLabel() {
      return this.$store.getters.translations.scHomePage.scHomeResourceLinks.groupTitle;
    },
    quickLinksImage() {
      return this.$store.getters.translations.scSettings.generalSettings.lifestyleImageUrl;
    },
    quickLinksList() {
      if (this.$store.getters.translations.scHomePage.scHomeResourceLinks.links) {
        return this.$store.getters.translations.scHomePage.scHomeResourceLinks.links
          .map((link) => ({
            text: link.fields.text,
            url: link.fields.link,
            gaEventString: `Support Landing,Internal Link,${link.fields.internal}`,
          }));
      }
      return [];
    },
    quickLinksImageSet() {
      const imageSet = [];

      if (this.$store.getters.translations.scSettings.generalSettings.lifestyleImageUrl) {
        imageSet.push({
          url: this.$store.getters.translations.scSettings.generalSettings.lifestyleImageUrl,
          width: 1600,
        });
      }

      if (this.$store.getters.translations.scSettings.generalSettings.lifestyleImageLarge) {
        imageSet.push({
          url: this.$store.getters.translations.scSettings.generalSettings.lifestyleImageLarge,
          width: 1440,
        });
      }

      if (this.$store.getters.translations.scSettings.generalSettings.lifestyleImageMedium) {
        imageSet.push({
          url: this.$store.getters.translations.scSettings.generalSettings.lifestyleImageMedium,
          width: 1200,
        });
      }

      if (this.$store.getters.translations.scSettings.generalSettings.lifestyleImageSmall) {
        imageSet.push({
          url: this.$store.getters.translations.scSettings.generalSettings.lifestyleImageSmall,
          width: 768,
        });
      }

      if (this.$store.getters.translations.scSettings.generalSettings.lifestyleImageExtraSmall) {
        imageSet.push({
          url: this.$store.getters.translations.scSettings.generalSettings.lifestyleImageExtraSmall,
          width: 480,
        });
      }

      // eslint-disable-next-line max-len
      if (this.$store.getters.translations.scSettings.generalSettings.lifestyleImageExtraExtraSmall) {
        imageSet.push({
          // eslint-disable-next-line max-len
          url: this.$store.getters.translations.scSettings.generalSettings.lifestyleImageExtraExtraSmall,
          width: 320,
        });
      }

      return imageSet;
    },
    socialMediaLinks() {
      if (this.$store.getters.translations.scHomePage.socialMediaLinks) {
        const links = this.$store.getters.translations.scHomePage.socialMediaLinks;
        return links.map((link) => ({
          url: link.fields.url,
          icon: link.fields.icon,
          gaEventString: `Support Landing,Social Icon CTA,${link.fields.internal}`,
        }));
      }

      return [];
    },
    ...mapGetters(['renderHome']),
  },
};
