<template>
  <div
    class="app"
    :class="{
      'app__init-complete': isViewingTransition()
    }"
  >
    <Global
      :recaptcha-flag="true"
      @global-initialization-complete="onInitCompleted"
    />
    <Loader :show="!initComplete" />

    <component
      :is="child.component"

      v-for="child in preInitChildren"
      :key="child.component.name"
      :custom-props="child.customProps"
      :children="child.children"
    />
    <template v-if="initComplete">
      <component
        :is="child.component"
        v-for="(child, index) in children"
        :key="index"
        :custom-props="child.customProps"
        :children="child.children"
      />
    </template>
  </div>
</template>

<script src="./default.js"></script>
