import './style.scss';
import { mapGetters } from 'vuex';
import ContentSidebar from '@garmin/support-center-components/components/content-sidebar/content-sidebar.vue';
import Results from '@garmin/support-center-components/components/results/results.vue';
import Faq from '@garmin/support-center-components/components/faq/faq.vue';
import TabTopics from '@garmin/support-center-components/components/tab-topics/tab-topics.vue';
import Manuals from '@garmin/support-center-components/components/manuals/manuals.vue';
import TabSoftware from '@garmin/support-center-components/components/tab-software/tab-software.vue';
import TabVideos from '@garmin/support-center-components/components/tab-videos/tab-videos.vue';
import TabRepair from '@garmin/support-center-components/components/tab-repair/tab-repair.vue';
import Contact from '@garmin/support-center-components/components/contact/contact.vue';
import TabWelcome from '@garmin/support-center-components/components/tab-welcome/tab-welcome.vue';
import AviationOTCSubviewTab from '../AviationOTCSubviewTab/index.vue';

export default {
  name: 'ContentWrapper',
  components: {
    ContentSidebar,
    results: Results,
    topics: TabTopics,
    faq: Faq,
    manuals: Manuals,
    software: TabSoftware,
    videos: TabVideos,
    repair: TabRepair,
    welcome: TabWelcome,
    'aviation-otc': AviationOTCSubviewTab,
    Contact,
  },
  props: {
    children: {
      default() {
        return [];
      },
      type: Array,
    },
    customProps: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'urlParams',
      'routedComponentToRender',
      'isFullWidthTab',
      'product',
      'noProductFlowFlag',
    ]),

    isAllowableTab() {
      return this.$store.getters.urlParams.tab === 'topics';
    },

    selected() {
      return this.$store.getters.routedComponentToRender;
    },

    generateWrapperClasses() {
      return {
        'sp__results__wrapper__content--fullWidth': this.isFullWidthTab,
        sp__results__wrapper__content: !this.isFullWidthTab,
      };
    },
  },
  watch: {
    urlParams: {
      handler(to, from) {
        if (this.urlParams.faq) {
          this.$store.commit('routedComponentToRenderUpdate', 'faq');
        } else if (
          !to.faq
          && ((to.topicTag || to.searchQuery) || (from.topicTag || from.searchQuery))
          && this.$store.getters.noProductFlowFlag) {
          if ((from.topicTag || from.searchQuery)
            && (!to.topicTag && !to.searchQuery)) {
            this.clearSearchState();
          }
          this.$store.commit('routedComponentToRenderUpdate', 'topics');
          localStorage.removeItem('faqs');
        } else if (
          !to.faq
          && (to.topicTag || to.searchQuery)
          && !this.$store.getters.noProductFlowFlag) {
          this.$store.commit('routedComponentToRenderUpdate', 'results');
          localStorage.removeItem('faqs');
        } else if (
          !to.faq
          && (to.topicTag || to.searchQuery)
          && (!to.productID && !to.identifier)) {
          this.$store.commit('noProductFlowFlagUpdate', true);
          this.$store.commit('routedComponentToRenderUpdate', 'topics');
          localStorage.removeItem('faqs');
        } else if (to.tab && (to.productID || to.identifier)) {
          this.$store.commit('routedComponentToRenderUpdate', this.urlParams.tab);
        }

        if (to.tab) {
          this.$store.commit('selectedTabUpdate', to.tab);
        }

        if (!this.urlParams.topicTag
          && (!this.urlParams.searchQuery || this.urlParams.searchQuery === '')
          && !this.urlParams.faq
          && this.urlParams.tab
        ) {
          this.$store.commit('routedComponentToRenderUpdate', this.urlParams.tab);
        }
      },
      deep: true,
    },
  },

  methods: {
    clearSearchState() {
      this.$store.dispatch('resetSearchUrlParams');
      this.$store.commit('isTopicSelectedFlagUpdate', false);
      this.$store.commit('isSearchQuerySubmittedFlagUpdate', false);
    },
  },
};
