// eslint-disable-next-line no-unused-vars
import { shallowRef } from 'vue';
import Global from '@garmin/support-center-components/components/global/global.vue';
import GlobalBanner from '@garmin/support-center-components/components/global-banner/global-banner.vue';
import ProductSelector from '@garmin/support-center-components/components/product-selector/product-selector.vue';
import Product from '@garmin/support-center-components/components/product/product.vue';
import ContentSidebar from '@garmin/support-center-components/components/content-sidebar/content-sidebar.vue';
import Loader from '@garmin/support-center-components/components/loader/loader.vue';
import Topics from '@garmin/support-center-components/components/tab-topics/tab-topics.vue';
import BreadCrumbs from '@garmin/support-center-components/components/breadcrumbs/breadcrumbs.vue';
import Faq from '@garmin/support-center-components/components/faq/faq.vue';
import PersistentContactCta from '@garmin/support-center-components/components/persistent-contact-cta/persistent-contact-cta.vue';
import UnavailableState from '@garmin/support-center-components/components/unavailable-state/unavailable-state.vue';
import ContentWrapper from '../components/CSCContentWrapper/index.vue';
import HomeView from './HomeView/index.vue';

import './default.scss';

export default {
  components: {
    Global,
    Loader,
  },
  created() {
    this.$store.dispatch(
      'fetchComponentizedTranslations',
      {
        pathsForEntryIds: ['banner', 'scContactSection[0]'],
      },
    );
    this.$store.dispatch('fetchSystemsAvailability');
    this.$store.commit('bypassGAWrapperFlagUpdate', true);
    this.$store.commit('productSearchErrorOverridesUpdate');
    this.$store.commit('productSearchOverridesUpdate');
  },
  data() {
    return {
      initComplete: false,

      preInitChildren: [
        {
          component: shallowRef(GlobalBanner),
          children: [],
        },
        {
          customProps: {
            invalidTopic: this.$store.state.translations.navigation.scBreadcrumb.invalidTopic,
          },
          component: shallowRef(BreadCrumbs),
        },
        {
          component: shallowRef(UnavailableState),
        },
        {
          component: shallowRef(ContentWrapper),
          customProps: {},
          children: [{
            component: shallowRef(ContentSidebar),
            children: [{
              component: shallowRef(Product),
              customProps: false,
              children: [],
            }, {
              component: shallowRef(Topics),
              customProps: false,
              children: [],
            }],
          }, {
            component: shallowRef(Faq),
            customProps: {},
            children: [],
          }],
        },
      ],

      children: [
        {
          component: shallowRef(HomeView),
          customProps: {},
          children: [],
        },
        {
          component: shallowRef(ProductSelector),
          customProps: {},
          children: [],
        },
        {
          component: shallowRef(PersistentContactCta),
          children: [],
          customProps: {},
        },
      ],
    };
  },
  methods: {
    async onInitCompleted(isComplete) {
      this.initComplete = isComplete;
    },

    isViewingTransition() {
      const params = new URLSearchParams(window.location.search);
      return params.has('focus');
    },
  },
};
